<template>
    <div class="search">
        <div class="search__title">
            <div class="search__title-emoji">
                <emoji emoji="mag" set="apple" :size="32" />
            </div>
            <h1 class="search__title-name">Результаты поиска</h1>
        </div>
        <div class="search__form-block">
            <label class="sr-only" for="search-input">Поиск</label>
            <div class="search__input-container">
                <icon
                    class="search__input-container-svg"
                    width="12"
                    height="12"
                >
                    <path
                        d="M8.30359 7.1208C9.74406 5.08673 9.26229 2.27014 7.22822 0.830012C5.19415 -0.610115 2.37756 -0.128696 0.937434 1.90572C-0.502693 3.93979 -0.0212742 6.75604 2.01314 8.19616C3.46497 9.22404 5.38445 9.30319 6.91576 8.39885L10.243 11.7061C10.6023 12.0843 11.2 12.0995 11.5782 11.7402C11.9564 11.3813 11.9715 10.7836 11.6126 10.4054C11.6013 10.3933 11.5902 10.3823 11.5782 10.371L8.30359 7.1208ZM4.61776 7.42913C3.01039 7.42947 1.70722 6.12734 1.70619 4.51997C1.70585 2.9126 3.00798 1.60943 4.61569 1.60875C6.22099 1.60806 7.52347 2.90813 7.52657 4.51343C7.52932 6.12114 6.22788 7.42638 4.61982 7.42913C4.61913 7.42913 4.61879 7.42913 4.61776 7.42913Z"
                        fill="#989CAE"
                    />
                </icon>
                <input
                    autocomplete="off"
                    class="search__form-input"
                    id="search-input"
                    type="text"
                    v-model.trim="search"
                    placeholder="Поиск по порталу"
                />
            </div>
        </div>

<!--        PAgeAll-{{data[page]}}-->
<!--        <hr/>-->
<!--        <hr/>-->
        <line-picker
            v-if="getPickerItems && getPickerItems.length"
            :items="getPickerItems"
        />
        <template v-if="!isLoading">
            <div
                v-if="data.length && data[page].data && data[page].data.length"
                class="search-block"
            >
                <template  v-for="item in data[page].data">
                    <SearchItem
                        :key="item.id"
                        :item="item"
                        class="search-block__item"
                    />
                </template>

            </div>
            <div v-else class="search__result-empty">
                <div class="search__result-empty-emoji">
                    <emoji emoji="eyes" set="apple" :size="32" />
                </div>
                <p class="search__result-empty-text">Ничего не найдено</p>
            </div>
        </template>
        <loading-spinner class="sidebar__spinner" v-else />
    </div>
</template>

<script>
    import { Emoji } from 'emoji-mart-vue';
    import LinePicker from '@/components/LinePicker';
    import SearchItem from '@/components/search/SearchItem';
    import session from '@/api/session';
    import LoadingSpinner from '@/components/LoadingSpinner';
    import Icon from '@/components/icon/Icon.vue';

    export default {
        name: 'SearchView',
        components: {
            Emoji,
            LinePicker,
            SearchItem,
            LoadingSpinner,
            Icon
        },
        computed: {
            getPickerItems: function() {
                return this.data
                .map((item, index) => ({
                    name: item.name,
                    count: item.count,
                    active: this.page === index,
                    onClick: () => {
                        this.page = index;
                    }
                }))
                    .filter(item => item.count !== 0 && item.name !== 'Теги');

                    // .filter(item => item.name === 'База знаний')
                    // .map((item, index) => ({
                    //     name: item.name,
                    //     count: item.count,
                    //     active: this.page === index,
                    //     onClick: () => {
                    //         this.page = index
                    //     }
                    // }))
            }
        },
        watch: {
            search: {
                handler: function(val, oldVal) {
                    this.sleep(1000).then(() => {
                        this.getSearch();
                    });
                },
                deep: true
            }
        },
        data() {
            return {
                search: this.$route.query.search || '',
                data: [],
                isLoading: true,
                page: 0
            };
        },
        methods: {
            sleep(ms) {
                return new Promise(resolve => {
                    this.isLoading = true;
                    if (this.timer) {
                        clearTimeout(this.timer);
                    }
                    this.timer = setTimeout(resolve, ms);
                });
            },
            async getSearch() {
                this.isLoading = true;

                await session
                    .get(`/api/v1/search/?search=${this.search}`)
                    .then(response => {
                        this.data = response.data;
                    })
                    .catch(error => {
                        this.data = [];
                        console.error(error);
                    });

                this.isLoading = false;
            }
        },
        mounted() {
            this.getSearch();
        }
    };
</script>

<style lang="scss" scoped>
    .search {
        max-width: 960px;
        margin: 0 auto;
        padding: 0 30px;
        &__title {
            display: flex;
            align-items: center;
            margin-bottom: 36px;
            &-emoji {
                display: flex;
                margin-right: 24px;
            }
            &-name {
                font-size: 40px;
                font-weight: 700;
                line-height: 52px;
                color: #000;
            }
        }
        &__input-container {
            display: flex;
            align-items: center;
            flex: 1;
            background: #ffffff;
            border: 1px solid #dbdbdb;
            border-radius: 2px;
            transition: border-color 200ms ease;
            &:focus-within {
                border-color: #97a5ba;
                outline: 0;
                box-shadow: none;
            }
            &-svg {
                margin: 0 13px;
            }
        }
        &__form {
            &-block {
                display: flex;
                margin-bottom: 32px;
            }
            &-input {
                flex: 1;
                height: 38px;
                border: none;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }
        &-block {
            display: flex;
            flex-direction: column;
            margin-top: 38px;
            & > .search-block-item + .search-block-item {
                margin-top: 16px;
            }
        }
        &__result-empty {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 50vh;
            &-emoji {
                margin-bottom: 12px;
            }
            &-text {
                margin: 0;
                font-size: 20px;
                line-height: 21px;
                color: #989cae;
            }
        }
    }
</style>
